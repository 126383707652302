
import { defineComponent, ref, onMounted } from "vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Order } from "@/core/model/Orders";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import moment from "moment";
import { displayErrors } from "@/core/helpers/errors";
import type { CatalogCategory } from "@/core/model/CatalogCategory";
import { setCurrentPageActions } from "@/core/helpers/toolbar";

export default defineComponent({
  name: "catalog-categories-list",
  components: {
  },
  setup() {
    const store = useStore();
    const loading = ref(false);
    const rowsPerPage = 1000;
    const currentPage = 1;
    const total = ref(0);
    const tableData = ref<Array<Order>>([]);
    let sortOrder = "asc";
    let sortField = "position";
    let currentSortColumn = ref("positionasc");
    const search = ref<string>("");
    let timerSearch: number | null = null;

    onMounted(() => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Categorías", ["Catálogo", "Categorías"]);
      setCurrentPageActions(false, false, []);
      loadData();
    });

    const loadData = () => {
      const params = {
        page_number: currentPage,
        page_size: rowsPerPage,
        sort_order: sortOrder,
        sort_field: sortField,
        search: search.value,
        filter_byparent: ''
      };
      loading.value = true;
      store
        .dispatch(Actions.CATALOG_CATEGORY_LIST, params)
        .then(() => {
          tableData.value.splice(
            0,
            tableData.value.length,
            ...store.state.CatalogModule.categories.items
          );
          loading.value = false;
          total.value = store.state.CatalogModule.categories.total;
        })
        .catch(() => {
          displayErrors(store, "CatalogModule");
          loading.value = false;
        });
    };

    const loadChildren = (
      row: CatalogCategory,
      treeNode: unknown,
      resolve: (_data: CatalogCategory[]) => void
    ) => {
      const params = {
        page_number: 1,
        page_size: 1000,
        sort_order: sortOrder,
        sort_field: sortField,
        search: '',
        filter_byparent: row.id
      };
      store
        .dispatch(Actions.CATALOG_CATEGORY_LIST, params)
        .then(() => {
          resolve(store.state.CatalogModule.categories.items);
        })
        .catch(() => {
          displayErrors(store, "CatalogModule");
        });
    }

    const searchItems = () => {
      if (timerSearch) {
        clearTimeout(timerSearch);
        timerSearch = null;
      }
      timerSearch = setTimeout(() => {
        loadData();
      }, 800);
    };

    const sortChange = (data) => {
      sortField = data.prop;
      sortOrder = data.order == "ascending" ? "asc" : "desc";
      loadData();
    };

    const formatDateTime = (value) => {
      return value === undefined
        ? ""
        : moment(value).format("DD/MM/YYYY hh:mm a");
    };

    return {
      tableData,
      search,
      searchItems,
      loading,
      total,
      sortChange,
      currentSortColumn,
      store,
      formatDateTime,
      loadChildren,
    };
  },
});
